import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import MicRounded from '@mui/icons-material/MicRounded';
import MicOffRounded from '@mui/icons-material/MicOffRounded';
import { UICtx } from '../../providers/UIProvider';
import { CopySmall } from '../Typography';
import { BotState } from '../../ifaces';

interface MicInputButtonProps {
  onInputAudio?: (audio: Blob) => void;
  onListening?: (isListening: boolean) => void;
  onVerifyMessage?: () => void;
  onHelpButtonPressed?: () => void;
  botState?: BotState;
  loading?: boolean;
  language?: string;
}

const MicInputButton: FC<MicInputButtonProps> = ({
  onInputAudio,
  onListening,
  loading,
  botState,
  language = 'fr',
}) => {
  const [permission, setPermission] = useState(false);
  const [requestingPermission, setRequestingPermission] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [firstMessageDisplayed, setFirstMessageDisplayed] = useState(false);
  const [explainMessage, setExplainMessage] = useState(
    language === 'fr'
      ? 'Appuyez sur le bouton ou sur la barre d’espace pour commencer la discussion.'
      : 'Press the button or the space bar to start the discussion.'
  );
  const [isSpaceKeyPressed, setIsSpaceKeyPressed] = useState(false);

  const { darkMode } = useContext(UICtx);

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window && !requestingPermission && !permission) {
      setRequestingPermission(true);
      console.log('Getting user media');
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        console.log(err);
        setPermission(false);
      } finally {
        setRequestingPermission(false);
      }
    } else {
      console.log('The MediaRecorder API is not supported in your browser.');
    }
  };

  const mimeType = 'audio/webm';
  const mediaRecorder = useRef<MediaRecorder>();
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);

  // Init mic on mount
  useEffect(() => {
    const initMic = async () => {
      await getMicrophonePermission();
    };
    initMic();
  }, []);

  const startRecording = useCallback(async () => {
    await getMicrophonePermission();

    // create new Media recorder instance using the stream
    if (!stream) {
      console.log('no stream');
      return;
    }
    const media = new MediaRecorder(stream, { mimeType: mimeType });
    if (!mediaRecorder) return;
    setRecordingStatus('recording');
    // set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    // invokes the start method to start the recording process
    mediaRecorder.current.start();
    // Call onListening handler if provided
    if (onListening) {
      setExplainMessage(
        language === 'fr'
          ? 'Appuyez sur la barre d’espace pour finir'
          : 'Press the space bar to stop'
      );
      onListening(true);
    }

    const localAudioChunks: Blob[] = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  }, [stream, onListening]);

  const stopRecording = useCallback(() => {
    if (!stream || !mediaRecorder || !mediaRecorder.current) return;
    setRecordingStatus('inactive');
    // stops the recording instance
    mediaRecorder.current.stop();
    if (onListening) {
      onListening(false);
    }
    mediaRecorder.current.onstop = () => {
      // Creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      if (onInputAudio) {
        onInputAudio(audioBlob);
      }
      setAudioChunks([]);
      setExplainMessage(
        language === 'fr'
          ? 'Appuyez sur la barre d’espace pour commencer à parler'
          : 'Press the space bar to start speaking'
      );
    };
  }, [audioChunks, mimeType, onInputAudio, onListening, stream]);

  // Modified key press listener for the space key
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.code === 'Space' && !isSpaceKeyPressed) {
        event.preventDefault();
        setIsSpaceKeyPressed(true);
        if (!permission) {
          setPermission(false);
          setStream(undefined);
          await getMicrophonePermission();
        }
        if (recordingStatus !== 'recording') {
          setFirstMessageDisplayed(true);
          startRecording();
        }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault();
        setIsSpaceKeyPressed(false);
        if (recordingStatus === 'recording') {
          stopRecording();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    permission,
    isSpaceKeyPressed,
    recordingStatus,
    startRecording,
    stopRecording,
    getMicrophonePermission,
  ]);

  const getStateColor = (state: BotState | undefined): string => {
    switch (state) {
      case BotState.LISTENING:
        // Light Green
        return '#91e3a1';
      case BotState.THINKING:
        // Light mustard
        return '#e3d691';
      case BotState.SPEAKING:
        return '#91b1e3';
      default:
        return '#EEE';
    }
  };

  return (
    <>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '50%',
          }}
        >
          {loading && botState !== BotState.LISTENING ? (
            <Stack
              sx={{
                color: darkMode ? '#888' : 'black',
                p: 3,
                // cursor: 'pointer',
              }}
              // onClick={() => {
              //   if (recordingStatus === 'recording') {
              //     stopRecording();
              //   }
              // }}
            >
              <CircularProgress
                sx={{
                  width: '40px',
                  height: '40px',
                  color: getStateColor(botState),
                }}
              />
            </Stack>
          ) : (
            <IconButton
              aria-label="Record"
              onClick={() => {
                if (!permission) {
                  setPermission(false);
                  setStream(undefined);
                  getMicrophonePermission();
                }
                //  else {
                //   if (recordingStatus === 'recording') {
                //     stopRecording();
                //   } else if (!loading) {
                //     startRecording();
                //   }
                // }
              }}
              size="large"
              sx={{
                color: darkMode ? '#888' : 'black',
                p: 3,
              }}
            >
              {recordingStatus === 'recording' ? (
                <MicOffRounded
                  sx={{
                    width: '40px',
                    height: '40px',
                  }}
                />
              ) : (
                <MicRounded
                  sx={{
                    width: '40px',
                    height: '40px',
                  }}
                />
              )}
            </IconButton>
          )}
        </Box>
      </Stack>
      {/* <CopySmall
        sx={{
          textAlign: 'center',
          lineHeight: '2rem',
          fontSize: '0.8rem',
        }}
      >
        {explainMessage}
      </CopySmall> */}
    </>
  );
};
export default MicInputButton;
