export type Props = {
  children?: React.ReactNode;
};

export type Size = {
  width: number;
  height: number;
};

export type Bot = {
  id: string;
  name: string;
};

export type Chat = {
  bot: Bot;
  id: string;
  language: string;
};

export type BaseChatMessage = {
  text: string;
  uid: string;
  chat_id: string;
  timestamp: number;
};

export type ChatMessage = BaseChatMessage & {
  uuid: string;
};

export enum BotState {
  IDLE,
  LISTENING,
  THINKING,
  SPEAKING,
}

export interface CustomNotification {
  id?: string;
  type: 'success' | 'warning' | 'error' | 'info';
  message: string;
}

export interface UsageTotal {
  input_audio_tokens: number;
  output_audio_tokens: number;
  input_text_tokens: number;
  output_text_tokens: number;
  cost: number;
}
