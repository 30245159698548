import { FC, RefObject, useEffect } from 'react';
import { WavRenderer } from './wav_renderer';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools';

const InputWaveformRenderer: FC<{
  wavRecorderRef: RefObject<WavRecorder>;
  clientCanvasRef: RefObject<HTMLCanvasElement>;
}> = ({ wavRecorderRef, clientCanvasRef }) => {
  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (!wavRecorder) {
        return;
      }

      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <div className="visualization-entry client">
      <canvas ref={clientCanvasRef} />
    </div>
  );
};

export default InputWaveformRenderer;
