import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Chat } from "../ifaces";
import { getChats } from "../API";
import { AuthCtx } from "./AuthProvider";
// --------------------------------------------------------

interface DataProps {
  isLoading: boolean;
  setLoading: (state: boolean) => void;
  fetchData: () => void;

  // Completion Code
  completionCode: number;
  setCCompletionCode: (code: number) => void;

  // Chats
  chats: Chat[];

  // Error management
  error: string;
}

export const DataCtx = createContext({} as DataProps);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Auth Context
  const { user } = useContext(AuthCtx);

  const [completionCode, setCompletionCode] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [chats, setChats] = useState<Chat[]>([]);
  const [error, setError] = useState<string>("");

  const fetchData = useCallback(async function () {
    try {
      setLoading(true);
      const response = await getChats();
      setChats(response.result);
    } catch (e) {
      console.error(e);
      setError("Error fetching data");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user, fetchData]);

  return (
    <DataCtx.Provider
      value={{
        isLoading,
        setLoading,
        fetchData: fetchData,
        completionCode,
        setCCompletionCode: setCompletionCode,
        chats,
        error,
      }}
    >
      {children}
    </DataCtx.Provider>
  );
};
