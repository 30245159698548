import React, { FC, useContext, useEffect, useRef, useState } from 'react';

// MUI
import { Stack } from '@mui/system';
import { Button, CircularProgress, IconButton, Switch } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Firebase
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
// import { getDatabase, ref as rdbRef, onValue } from 'firebase/database';

// Custom
import ChatInputField from './ChatInputField';
import ChatPageCard from './ChatCard';
import { BaseChatMessage, BotState, ChatMessage } from '../../ifaces';
import { resetChat, sendMessage, transcribe } from '../../API';
import BotFace from './BotFace/BotFace';
import { AuthCtx } from '../../providers/AuthProvider';
import { UICtx } from '../../providers/UIProvider';
import MicInputButton from './MicInputButton';
import { REACT_APP_DEBUG } from '../../config';
import { CopyBold } from '../Typography';
import Legend from './Legend/Legend';

interface ChatUIProps {
  onTextInput?: (text: string) => void;
  chatUuid: string;
  onStopButtonPress?: () => void;
  preloaded_messages?: ChatMessage[];
}

const ChatUI: FC<ChatUIProps> = ({
  onTextInput,
  chatUuid,
  onStopButtonPress,
  preloaded_messages = [],
}) => {
  const { user } = useContext(AuthCtx);
  const { darkMode } = useContext(UICtx);

  // UI State
  const [displayBotLegend, setDisplayBotLegend] = useState<boolean>(false);
  const [displayBotFace, setDisplayBotFace] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayBotFace(event.target.checked);
  };

  // Audio State
  const [playingAudio, setPlayingAudio] = useState<boolean>(false);
  const [recordingAudio, setRecordingAudio] = useState<boolean>(false);

  // Local Loading
  const [loading, setLoading] = useState<boolean>(false);

  // Chat State
  const [botState, setBotState] = useState<BotState>(BotState.IDLE);
  const [loadingBotAnswer, setLoadingBotAnswer] = useState<boolean>(false);
  // const [chatState, setChatState] = useState<{ topic: string; state: BotState }>({ topic: '', state: BotState.IDLE });
  const [messages, setMessages] =
    useState<Array<ChatMessage | BaseChatMessage>>(preloaded_messages);

  // const [currentInputText, setCurrentInputText] = useState<string>();

  // Last Input Timeout
  const [lastInputTimeout, setLastInputTimeout] = useState<NodeJS.Timeout>();

  // Ref for the message container
  const messageContainerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(async () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    }, 200);
  };

  useEffect(() => {
    if (!displayBotFace) {
      scrollToBottom();
    }
  }, [displayBotFace]);

  const handleSendMessage = async (newMessage: string) => {
    let newMesages: Array<BaseChatMessage> = [
      ...messages,
      {
        text: newMessage,
        chat_id: chatUuid,
        uid: user?.uid || 'default_user',
        timestamp: new Date().getTime(),
      },
    ];
    setTimeout(async () => {
      if (user && newMessage !== '') {
        setMessages(newMesages);
        scrollToBottom();
        if (onTextInput) {
          onTextInput('');
        }
        scrollToBottom();
        setLoadingBotAnswer(true);
        try {
          const response = await sendMessage(newMessage, chatUuid);
          setLoadingBotAnswer(false);

          newMesages = [...newMesages, response];
          setMessages(newMesages);

          // Create a reference with an initial file path and name
          const storage = getStorage();
          // Create a reference from an HTTPS URL
          // Note that in the URL, characters are URL escaped!
          const httpsReference = ref(storage, response.audio_url);

          const audio_url = await getDownloadURL(httpsReference);

          // Play Audio from Firebase Storage
          const audio = new Audio(audio_url);

          audio.onended = async () => {
            if (response.follow_up_audio_url) {
              console.log('Playing follow up audio');
              const follow_up_audio_url = ref(
                storage,
                response.follow_up_audio_url
              );
              const follow_up_audio_url_download_url = await getDownloadURL(
                follow_up_audio_url
              );
              const follow_up_audio = new Audio(
                follow_up_audio_url_download_url
              );
              follow_up_audio.onended = () => {
                setPlayingAudio(false);
              };
              setPlayingAudio(true);
              await follow_up_audio.play();
            }
            setPlayingAudio(false);
          };

          setPlayingAudio(true);
          await audio.play();
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingBotAnswer(false);
        }

        scrollToBottom();
      } else {
        setMessages(newMesages);
      }
    }, 200);
  };

  const handleResetChat = async () => {
    if (messages.length === 0) {
      return;
    }
    setLoading(true);
    await resetChat(chatUuid);
    setMessages([]);
    setTimeout(async () => {
      scrollToBottom();
    }, 200);
    setLoading(false);
  };

  useEffect(() => {
    setMessages(preloaded_messages);
  }, [preloaded_messages]);

  // useEffect(() => {
  //   const db = getDatabase();
  //   const chatStateRef = rdbRef(db, 'chats/' + chatUuid);
  //   const unsubscribe = onValue(chatStateRef, snapshot => {
  //     const data = snapshot.val() as BotState;
  //     console.log(data);
  //   });

  //   const fetchMessages = async () => {
  //     const chatData = await getChat(chatUuid);
  //     // setChat(startChartResponse.chat);
  //     setMessages(chatData.messages);
  //     setLoading(false);
  //     setTimeout(async () => {
  //       scrollToBottom();
  //     }, 200);
  //   };

  //   fetchMessages();

  //   return unsubscribe;
  // }, [chatUuid]);

  const handleInputAudio = async (audio: Blob) => {
    const transcribeResponse = await transcribe(audio);
    await handleSendMessage(transcribeResponse.result);

    if (lastInputTimeout) {
      clearTimeout(lastInputTimeout);
    }

    const inputTimeout = setTimeout(async () => {}, 1000);

    setLastInputTimeout(inputTimeout);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const handleResize = () => {
      const { width, height } = container.getBoundingClientRect();
      // Update the layout state
      setSize({
        width: width,
        height: height,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, displayBotFace]);

  useEffect(() => {
    if (playingAudio && !recordingAudio) {
      setBotState(BotState.SPEAKING);
    } else if (!playingAudio && recordingAudio) {
      setBotState(BotState.LISTENING);
    } else if (!playingAudio && !recordingAudio && loadingBotAnswer) {
      setBotState(BotState.THINKING);
    } else {
      setBotState(BotState.IDLE);
    }
  }, [playingAudio, recordingAudio, loadingBotAnswer]);

  return (
    <Stack
      sx={{
        flex: 1,
        p: 1,
        background: darkMode ? '#1e1e1e' : 'white',
        position: 'relative',
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        sx={{
          p: 1,
        }}
        spacing={1}
      >
        {/* //////////////////////////////////////////////////////////////////// */}
        {/* ////                             MENU                           //// */}
        {/* //////////////////////////////////////////////////////////////////// */}

        {/* {REACT_APP_DEBUG && ( */}
        <Stack
          sx={{
            background: darkMode ? '#282c30' : undefined,
            flex: 1,
            p: 1,
            px: 2,
            m: 1,
            borderRadius: '5px',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          direction={'row'}
        >
          <Button
            sx={{
              color: darkMode ? 'white' : 'black',
              width: '60px',
              height: '30px',
              fontSize: '12px',
              background: darkMode ? '#1e1e1e' : 'white',
            }}
            onClick={handleResetChat}
          >
            Reset
          </Button>
        </Stack>
        {/* )} */}
        <Switch
          checked={displayBotFace}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
        />
        {/* //////////////////////////////////////////////////////////////////// */}
        {/* ////                          STOP BUTTON                       //// */}
        {/* //////////////////////////////////////////////////////////////////// */}

        {/* <Button
          sx={{
            color: 'white',
            width: '60px',
            height: '30px',
            fontSize: '12px',
            background: 'green',
            fontWeight: 'bold',
            '&:hover': {
              background: 'green',
            },
          }}
          onClick={onStopButtonPress}
        >
          FIN
        </Button> */}
      </Stack>

      {/* //////////////////////////////////////////////////////////////////// */}
      {/* ////                           BOT FACE                         //// */}
      {/* //////////////////////////////////////////////////////////////////// */}

      {displayBotFace && (
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
          }}
          spacing={1}
        >
          <Stack
            ref={containerRef}
            sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
            spacing={2}
          >
            <BotFace
              darkMode={darkMode}
              state={botState}
              containerHeight={size.height}
              containerWidth={size.width}
            />
          </Stack>

          {/* //////////////////////////////////////////////////////////////// */}
          {/* ////                    MICROPHONE BUTTON                   //// */}
          {/* //////////////////////////////////////////////////////////////// */}

          {
            <MicInputButton
              onInputAudio={handleInputAudio}
              onListening={setRecordingAudio}
              loading={
                recordingAudio ||
                botState === BotState.SPEAKING ||
                botState === BotState.THINKING
              }
              botState={botState}
            />
          }

          {/* //////////////////////////////////////////////////////////////// */}
          {/* ////                      LEGEND DIALOG                     //// */}
          {/* //////////////////////////////////////////////////////////////// */}

          <IconButton
            aria-label="Record"
            onClick={() => {
              setDisplayBotLegend(true);
            }}
            size="large"
            sx={{
              color: darkMode ? '#888' : 'black',
              p: 0,
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <HelpOutlineIcon
              sx={{
                width: '30px',
                height: '30px',
              }}
            />
          </IconButton>
          <Legend
            open={displayBotLegend}
            onClose={() => setDisplayBotLegend(false)}
          />
        </Stack>
      )}

      {/* //////////////////////////////////////////////////////////////////// */}
      {/* ////                          CHAT MESSAGES                     //// */}
      {/* //////////////////////////////////////////////////////////////////// */}

      {!displayBotFace && [
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
          }}
        >
          {loading ? (
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <CircularProgress sx={{ position: 'absolute', color: 'white' }} />
            </Stack>
          ) : (
            <Stack
              ref={messageContainerRef}
              sx={{
                display: 'relative',
                // absolute positioning
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                // vertical scrolling
                overflowY: 'auto',
                // Customize the scrollbar
                '&::-webkit-scrollbar': {
                  width: '0.4em',
                },
              }}
              p={2}
              spacing={2}
            >
              {messages.map((message) => (
                <ChatPageCard
                  position={message.uid === 'bot' ? 'left' : 'right'}
                  text={message.text}
                />
              ))}
            </Stack>
          )}
        </Stack>,
      ]}

      {/* //////////////////////////////////////////////////////////////////// */}
      {/* ////                       CHAT INPUT FIELD                     //// */}
      {/* //////////////////////////////////////////////////////////////////// */}

      {!displayBotFace && (
        <Stack
          sx={{
            background: darkMode ? '#282c30' : undefined,
            p: 2,
            m: 1,
            borderRadius: '5px',
          }}
        >
          <ChatInputField
            onInputText={handleSendMessage}
            onInputAudio={handleInputAudio}
            onListening={setRecordingAudio}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ChatUI;
