import axios from 'axios';
import { headerConfig, headerAuthConfig } from './Firebase';
import {
  API_URL,
  REACT_APP_TOKEN_REDEEM_URL,
  REACT_APP_PX_API_URL,
} from './config';
import { Chat, ChatMessage } from './ifaces';

type StatusResponse = {
  status:
    | 'OK'
    | 'REQUEST_DENIED'
    | 'INVALID_REQUEST'
    | 'UNKNOWN_ERROR'
    | 'ZERO_RESULTS';
};

export const transcriptInputAudio = async (audio: Blob) => {
  // Create a new FormData object and append the audio blob
  const formData = new FormData();
  formData.append('file', audio);

  // Send the audio blob to the server
  await axios.post(REACT_APP_PX_API_URL + '/transcript', formData, {
    headers: {
      ...(await headerAuthConfig()),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const handleInputAudio = async (audio: Blob) => {
  // Create a new FormData object and append the audio blob
  const formData = new FormData();
  formData.append('file', audio);

  // Send the audio blob to the server
  await axios.post(REACT_APP_PX_API_URL + '/talk', formData, {
    headers: {
      ...(await headerAuthConfig()),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type SendMessageResponse = {
  uuid: string;
  text: string;
  audio_url: string;
  follow_up_audio_url?: string;
  uid: string;
  chat_id: string;
  timestamp: number;
  language_normalized: string;
  voice_id: string;
  voice_age: string;
  voice_gender: string;
  number_sequence: number[];
};

export const sendMessage = async (
  message: string,
  chat_uuid: string
): Promise<SendMessageResponse> => {
  const response = await axios.post(
    `${REACT_APP_PX_API_URL}/chats/${chat_uuid}/`,
    { message: message, withAudio: true },
    await headerConfig()
  );
  return response.data as SendMessageResponse;
};

export type TranscribeResponse = StatusResponse & {
  result: string;
};

export const transcribe = async (audio: Blob): Promise<TranscribeResponse> => {
  // Create a new FormData object and append the audio blob
  const formData = new FormData();
  formData.append('file', audio);

  // Send the audio blob to the server
  const response = await axios.post(
    REACT_APP_PX_API_URL + '/audio/transcribe',
    formData,
    {
      headers: {
        ...(await headerAuthConfig()),
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data as TranscribeResponse;
};

export const talk = async (
  audio: Blob,
  chat_uuid: string
): Promise<SendMessageResponse> => {
  // Create a new FormData object and append the audio blob
  const formData = new FormData();
  formData.append('file', audio);

  // Send the audio blob to the server
  const transcribeResponse = await axios.post(
    REACT_APP_PX_API_URL + '/audio/transcribe',
    formData,
    {
      headers: {
        ...(await headerAuthConfig()),
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  const response = await axios.post(
    `${REACT_APP_PX_API_URL}/chats/${chat_uuid}/`,
    { message: (transcribeResponse.data as TranscribeResponse).result },
    await headerConfig()
  );
  return response.data as SendMessageResponse;
};

// Chats
export type GetChatsResponse = StatusResponse & {
  result: Chat[];
};

export const getChats = async (): Promise<GetChatsResponse> => {
  const response = await axios.get(
    `${REACT_APP_PX_API_URL}/chats/`,
    await headerConfig()
  );
  return response.data as GetChatsResponse;
};

export type GetChatResponse = StatusResponse & {
  result: Chat;
};

export const getChat = async (chat_id: string): Promise<GetChatResponse> => {
  const response = await axios.get(
    `${REACT_APP_PX_API_URL}/chats/${chat_id}/`,
    await headerConfig()
  );
  return response.data as GetChatResponse;
};

export type CreateChatParams = {
  bot_id?: string;
  language: string;
};

export const createChat = async (params: CreateChatParams): Promise<Chat> => {
  const response = await axios.post(
    `${REACT_APP_PX_API_URL}/chats/`,
    params,
    await headerConfig()
  );
  return response.data as Chat;
};

export const resetChat = async (chat_id: string): Promise<void> => {
  await axios.put(
    `${REACT_APP_PX_API_URL}/chats/${chat_id}/reset`,
    {},
    await headerConfig()
  );
};

export const deleteChat = async (chat_id: string): Promise<void> => {
  await axios.delete(
    `${REACT_APP_PX_API_URL}/chats/${chat_id}`,
    await headerConfig()
  );
};

// Messages

export type GetChatMessagesResponse = StatusResponse & {
  result: ChatMessage[];
};

export const getChatMessages = async (
  chat_id: string
): Promise<GetChatMessagesResponse> => {
  const response = await axios.get(
    `${REACT_APP_PX_API_URL}/chats/${chat_id}/messages`,
    await headerConfig()
  );
  return response.data as GetChatMessagesResponse;
};

// AUTH

type RedeemTokenResponse = {
  token: string;
};

export const redeemInviteToken = async (
  token: string
): Promise<RedeemTokenResponse> => {
  const res = await axios.post(
    `${API_URL}/token`,
    {
      token: token,
    },
    await headerConfig()
  );
  return res.data as RedeemTokenResponse;
};

export const redeemInviteCode = async (
  code: number
): Promise<RedeemTokenResponse> => {
  const res = await axios.post(`${REACT_APP_TOKEN_REDEEM_URL}/token`, {
    code: code,
  });
  return res.data as RedeemTokenResponse;
};

type StopInviteResponse = {
  status: string;
  completionCode: number;
};

export const stopSurvey = async (): Promise<StopInviteResponse> => {
  const res = await axios.put(`${API_URL}/stop`, {}, await headerConfig());
  return res.data as StopInviteResponse;
};

type GetCompletionCodeResponse = {
  status: string;
  completionCode: number;
};

export const getCompletionCode =
  async (): Promise<GetCompletionCodeResponse> => {
    const res = await axios.get(`${API_URL}/completion`, await headerConfig());
    return res.data as GetCompletionCodeResponse;
  };
