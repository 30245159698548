export const firebaseConfig = {
  apiKey: 'AIzaSyD93scO5yq73FT-rK7JAY7hRcRcc2f_neU',
  authDomain: 'prx2023-e4d80.firebaseapp.com',
  projectId: 'prx2023-e4d80',
  storageBucket: 'prx2023-e4d80.appspot.com',
  messagingSenderId: '1068355325269',
  appId: '1:1068355325269:web:1ed51f672e10fbb740b3dc',
  databaseURL:
    'https://prx2023-e4d80-default-rtdb.europe-west1.firebasedatabase.app',
};

export const API_URL = process.env.REACT_APP_API_URL || '';
export const REACT_APP_PX_API_URL = process.env.REACT_APP_PX_API_URL || '';
export const REACT_APP_TOKEN_REDEEM_URL =
  process.env.REACT_APP_TOKEN_REDEEM_URL || '';
export const REACT_APP_DEBUG =
  process.env.REACT_APP_DEBUG === 'true' ? true : false;
