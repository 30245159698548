import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { FC, useContext } from 'react';
import { defaultColors } from '../theme';
import { UICtx } from '../providers/UIProvider';

interface CustomTypographyProps extends TypographyProps {
  isMobile?: boolean;
}

const fontFamily = "'Plus Jakarta Sans', sans-serif";

export const BigTitle: FC<CustomTypographyProps> = ({ children, isMobile, sx, onClick }) => {
  const { darkMode } = useContext(UICtx);

  return (
    <Typography
      component={'h1'}
      onClick={onClick}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '46px' : '88px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '42px' : '96px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Header1: FC<CustomTypographyProps> = ({ children, isMobile, sx, onClick }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'h1'}
      onClick={onClick}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '46px' : '56px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '40px' : '48px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Header2: FC<CustomTypographyProps> = ({ children, isMobile, sx, onClick }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'h2'}
      onClick={onClick}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '38px' : '38px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '32px' : '32px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Header3: FC<CustomTypographyProps> = ({ children, isMobile, sx, onClick }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'h3'}
      onClick={onClick}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '38px' : '40px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: '32px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Copy: FC<CustomTypographyProps> = ({ children, sx, onClick }) => {
  const { darkMode, isMobile } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      onClick={onClick}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '26px' : '28px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '18px' : '20px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CopyBold: FC<CustomTypographyProps> = ({ children, isMobile, sx, onClick }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      onClick={onClick}
      sx={{
        fontFamily: fontFamily,
        lineHeight: '26px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '18px' : '20px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CopySmall: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: fontFamily,
        lineHeight: '22px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '14px' : '16px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const CopySmallBold: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        lineHeight: isMobile ? '22px' : '20px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '14px' : '16px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const QuoteBold: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '30px' : '46px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '24px' : '36px',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Quote: FC<CustomTypographyProps> = ({ children, isMobile, sx }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: fontFamily,
        lineHeight: isMobile ? '30px' : '46px',
        color: darkMode ? 'white' : defaultColors.almostBlack,
        fontSize: isMobile ? '24px' : '36px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
