import React, { ChangeEvent, FC } from 'react';

// MUI
import { InputBase } from '@mui/material';

interface DigitInputFieldProps {
  size?: 'sm' | 'md' | 'lg';
  codeInputNumber: number;
  autoFocus?: boolean;
  localInputState: string;
  localInputStateRef: React.RefObject<HTMLInputElement>;
  handleTextChange: (field_number: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleKeyPress: (field_number: number, event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => void;
}

const DigitInputField: FC<DigitInputFieldProps> = ({
  size = 'md',
  autoFocus = false,
  codeInputNumber,
  localInputState,
  localInputStateRef,
  handleTextChange,
  handleKeyPress,
}) => {
  const getSize = (): string => {
    switch (size) {
      case 'sm':
        return '40px';
      case 'md':
        return '60px';
      case 'lg':
        return '80px';
      default:
        return '60px';
    }
  };

  const getFontSize = (): string => {
    switch (size) {
      case 'sm':
        return '20px';
      case 'md':
        return '30px';
      case 'lg':
        return '40px';
      default:
        return '30px';
    }
  };

  return (
    <InputBase
      onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        handleTextChange(codeInputNumber, event)
      }
      onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) =>
        handleKeyPress(codeInputNumber, event)
      }
      value={localInputState}
      inputRef={localInputStateRef}
      inputProps={{ maxLength: 1 }}
      tabIndex={codeInputNumber}
      type="password"
      autoFocus={autoFocus}
      sx={{
        color: 'white',
        pr: 0,
        // m: 0,
        borderRadius: '8px',
        // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
        // Trick to hide placeholder text when input is focused
        '.MuiInputBase-input:focus::placeholder': {
          color: 'transparent',
        },
        height: getSize(),
        width: getSize(),
        border: '2px solid white',
        fontSize: getFontSize(),
        textAlign: 'center',
        // Style for the input element itself
        '& input': {
          textAlign: 'center',
          fontSize: getFontSize(),
          fontWeight: 500,
        },
      }}
    />
  );
};

export default DigitInputField;
