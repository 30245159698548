import { styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { UICtx } from '../../providers/UIProvider';

const CustomCard = styled('div')({
  padding: '20px',
});

interface ChatPageCardProps {
  position: 'left' | 'right';
  text: string;
  message_variant?: string;
}

const ChatPageCard: FC<ChatPageCardProps> = ({ position, text }) => {
  const { darkMode } = React.useContext(UICtx);

  const botCardBackground = darkMode ? '#252c2e' : 'white';
  const botCardFontColor = darkMode ? 'white' : 'black';

  const userCardFontColor = darkMode ? 'black' : 'white';

  return (
    <div style={{ display: 'flex' }}>
      <CustomCard
        sx={{
          boxShadow: !darkMode ? 'rgb(32 54 86 / 15%) 0px 8px 20px' : undefined,
          borderRadius: position === 'left' ? '20px 20px 20px 0px' : '20px 20px 0px 20px',
          marginLeft: position === 'left' ? '0px' : 'auto',
          background: position === 'right' ? '#2ea1c3' : botCardBackground,
          color: position === 'right' ? userCardFontColor : botCardFontColor,
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: '1rem' }}>{text}</Typography>
      </CustomCard>
    </div>
  );
};

export default ChatPageCard;
