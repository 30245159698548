import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

// MUI
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import './Firebase';

// PROVIDERS
import { UIProvider } from './providers/UIProvider';
import { AuthProvider } from './providers/AuthProvider';
import { DataProvider } from './providers/DataProvider';

// PAGES
import theme from './theme';
import App from './App';
import Page404 from './pages/404Page/Page404';
import SecureLayout from './pages/SecureLayout';
import SignInPage from './pages/SignInPage/SignInPage';
import CompletionCodePage from './pages/CompletionCodePage/CompletionCodePage';
import PXChatPage from './pages/PXChatPage/PXChatPage';
import PXPage from './pages/PXPage/PXPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <UIProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <DataProvider>
            <BrowserRouter>
              <Routes>
                {/* //////////////////////////////////////////////////////////////////////// */}
                {/* ////                            Token Sign In                       //// */}
                {/* //////////////////////////////////////////////////////////////////////// */}

                <Route
                  path="/login"
                  element={<SignInPage allowSignInWithCode />}
                />

                {/* //////////////////////////////////////////////////////////////////////// */}
                {/* ////                           REALTIME TEST                        //// */}
                {/* //////////////////////////////////////////////////////////////////////// */}

                <Route
                  path="/"
                  element={
                    <SecureLayout>
                      {/* <App /> */}
                      <PXPage />
                    </SecureLayout>
                  }
                />

                <Route
                  path="/:uuid"
                  element={
                    <SecureLayout>
                      <PXChatPage />
                    </SecureLayout>
                  }
                />

                {/* //////////////////////////////////////////////////////////////////////// */}
                {/* ////                                 PX                             //// */}
                {/* //////////////////////////////////////////////////////////////////////// */}

                <Route
                  path="/completion"
                  element={
                    <SecureLayout>
                      <CompletionCodePage />
                    </SecureLayout>
                  }
                />

                {/* //////////////////////////////////////////////////////////////////////// */}
                {/* ////                          NOT FOUND PAGE                        //// */}
                {/* //////////////////////////////////////////////////////////////////////// */}

                <Route path="*" element={<Page404 />} />
              </Routes>
            </BrowserRouter>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </UIProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
