import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import MicRounded from '@mui/icons-material/MicRounded';
import MicOffRounded from '@mui/icons-material/MicOffRounded';
import SendIcon from '@mui/icons-material/Send';
import { Chat } from '../../ifaces';
import { UICtx } from '../../providers/UIProvider';
import { WavRecorder, WavStreamPlayer } from '../../lib/wavtools';
import InputWaveformRenderer from '../../utils/input_wave_renderer';

interface ChatInputFieldProps {
  inputText?: string;
  onInputText?: (message: string) => void;
  onInputAudio?: (audio: Blob) => void;
  onListening?: (isListening: boolean) => void;
  onVerifyMessage?: () => void;
  onHelpButtonPressed?: () => void;
  micInputAllowed?: boolean;
  chat?: Chat;
}

const ChatInputField: FC<ChatInputFieldProps> = ({
  inputText,
  onInputText,
  micInputAllowed = false,
  onInputAudio,
  onListening,
}) => {
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );

  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );

  const typeHereText = 'Type here...';

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState<MediaStream>();

  const { darkMode } = useContext(UICtx);
  const [localInputState, setLocalInputState] = useState('');

  const handleSendMessage = () => {
    if (onInputText && localInputState !== '') {
      onInputText(localInputState);
      setLocalInputState('');
    }
  };

  // Handle enter key press
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onInputText) {
      handleSendMessage();
    }
  };

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newVal = event.target.value;
    setLocalInputState(newVal);
  };

  // useEffect(() => {
  //   const initMic = async () => {
  //     await getMicrophonePermission();
  //   };
  //   if (micInputAllowed) {
  //     initMic();
  //   }
  // }, [micInputAllowed]);

  useEffect(() => {
    if (inputText) {
      setLocalInputState(inputText);
    }
  }, [inputText]);

  // const getMicrophonePermission = async () => {
  //   if ('MediaRecorder' in window) {
  //     try {
  //       const streamData = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //         video: false,
  //       });
  //       setPermission(true);
  //       setStream(streamData);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   } else {
  //     alert('The MediaRecorder API is not supported in your browser.');
  //   }
  // };

  const [isRecording, setIsRecording] = useState(false);

  const mimeType = 'audio/webm';
  const mediaRecorder = useRef<MediaRecorder>();
  // const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  // const [audio, setAudio] = useState<string>();

  // const startRecording = async () => {
  //   // create new Media recorder instance using the stream
  //   if (!stream) {
  //     console.log('no stream');
  //     return;
  //   }
  //   const media = new MediaRecorder(stream, { mimeType: mimeType });
  //   if (!mediaRecorder) return;
  //   setRecordingStatus('recording');
  //   // set the MediaRecorder instance to the mediaRecorder ref
  //   mediaRecorder.current = media;
  //   // invokes the start method to start the recording process
  //   mediaRecorder.current.start();

  //   // Call onListening handler if provided
  //   if (onListening) {
  //     onListening(true);
  //   }

  //   const localAudioChunks: Blob[] = [];
  //   mediaRecorder.current.ondataavailable = event => {
  //     if (typeof event.data === 'undefined') return;
  //     if (event.data.size === 0) return;
  //     localAudioChunks.push(event.data);
  //   };
  //   setAudioChunks(localAudioChunks);
  // };

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();
  }, []);

  const startRecording = async () => {
    setIsRecording(true);
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    // if (trackSampleOffset?.trackId) {
    //   const { trackId, offset } = trackSampleOffset;
    //   await client.cancelResponse(trackId, offset);
    // }
    // await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  // const stopRecording = () => {
  //   if (!stream || !mediaRecorder || !mediaRecorder.current) return;
  //   setRecordingStatus('inactive');
  //   // stops the recording instance
  //   mediaRecorder.current.stop();
  //   if (onListening) {
  //     onListening(false);
  //   }
  //   mediaRecorder.current.onstop = () => {
  //     //creates a blob file from the audiochunks data
  //     const audioBlob = new Blob(audioChunks, { type: mimeType });
  //     // creates a playable URL from the blob file.
  //     // const audioUrl = URL.createObjectURL(audioBlob);
  //     // setAudio(audioUrl);
  //     if (onInputAudio) {
  //       onInputAudio(audioBlob);
  //     }
  //     setAudioChunks([]);
  //   };
  // };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    // const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    // client.createResponse();
  };

  return (
    <InputBase
      onChange={handleTextChange}
      // Listen for enter key press
      onKeyPress={handleKeyPress}
      value={localInputState}
      placeholder={typeHereText}
      sx={{
        color: darkMode ? 'white' : 'black',
        pr: 0,
        // m: 0,
        borderRadius: '10px',
        // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
        // Trick to hide placeholder text when input is focused
        '.MuiInputBase-input:focus::placeholder': {
          color: 'transparent',
        },
      }}
      startAdornment={
        micInputAllowed && (
          <InputAdornment position="start">
            <IconButton
              aria-label="Record"
              onClick={() => {
                if (!permission) {
                  setPermission(false);
                  setStream(undefined);
                  // getMicrophonePermission();
                } else {
                  if (isRecording) {
                    stopRecording();
                  } else {
                    startRecording();
                  }
                }
              }}
              sx={{
                color: darkMode ? '#888' : 'black',
              }}
            >
              {isRecording ? (
                <InputWaveformRenderer
                  wavRecorderRef={wavRecorderRef}
                  clientCanvasRef={clientCanvasRef}
                />
              ) : (
                <MicRounded />
              )}
            </IconButton>
          </InputAdornment>
        )
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleSendMessage}
            // onMouseDown={handleMouseDownPassword}
            // edge="end"
            sx={{
              color: darkMode ? 'white' : 'black',
              // mr: "0px"
            }}
          >
            <SendIcon sx={{ width: 18 }} />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
export default ChatInputField;
