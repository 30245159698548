import { FC, useContext } from "react";
import { Helmet } from "react-helmet";

// MUI
import { Box } from "@mui/material";
import { Props } from "../ifaces";
import { UICtx } from "../providers/UIProvider";
import { FixedColors } from "../theme";

const MainLayout: FC<Props & { orientation?: "row" | "column" }> = ({
  children,
  orientation = "column",
}) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: orientation,
        position: "relative",
        flex: 1,
        width: "100%",
        background: darkMode ? FixedColors.almostBlack : "white",
      }}
    >
      <Helmet>
        <style type="text/css">{`
        body {
            background-color: ${
              darkMode ? FixedColors.almostBlack : "#ffffff"
            } !important};
        }
    `}</style>
        <meta name="mobile-web-app-capable" content={"yes"} />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={darkMode ? "black-translucent" : "default"}
        />
      </Helmet>
      {children}
    </Box>
  );
};

export default MainLayout;
