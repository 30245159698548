import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Stack } from '@mui/material';
import ChatUI from '../../components/ChatUI/ChatUI';
import { UICtx } from '../../providers/UIProvider';
import NavigationBar from '../../components/NavigationBar';
import StopConfirmModal from '../../components/StopConfirmationModal/StopConfirmModal';
import { getChatMessages, stopSurvey } from '../../API';
import { defaultColors } from '../../theme';
import { ChatMessage } from '../../ifaces';
import LoadingPage from '../LoadingPage/LoadingPage';
import { CopySmall, Header3 } from '../../components/Typography';
import Legend from '../../components/ChatUI/Legend/Legend';

const PXChatPage = () => {
  const navigate = useNavigate();

  // UI Context
  const { isMobile } = useContext(UICtx);

  // Load uuid from URL parameter
  const { uuid } = useParams();

  // State
  const [stopConfirmModalOpen, setStopConfirmModalOpen] = useState(false);
  const [startTime, setStartTime] = useState<Date>();
  const [localLoading, setLocalLoading] = useState(true);
  const [didLoadInitialMessages, setDidLoadInitialMessages] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const handleStopButtonPress = async () => {
    setStopConfirmModalOpen(true);
  };

  const handleConfirmStopSurvey = async () => {
    await stopSurvey();
    navigate('/completion');
  };

  useEffect(() => {
    setStartTime(new Date());
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!uuid) return;
      setLocalLoading(true);
      try {
        const messagesResponse = await getChatMessages(uuid);
        setMessages(messagesResponse.result);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Now TypeScript knows this is an AxiosError
          const errorCode = error.response?.status;
          // Handle different status codes here
          if (errorCode === 404 || errorCode === 403) {
            // Handle 404 error
            navigate('/');
          }
        } else {
          // Handle non-Axios errors here
        }
      } finally {
        setLocalLoading(false);
        setDidLoadInitialMessages(true);
      }
    };
    fetchMessages();
  }, [uuid, navigate]);

  if (localLoading) {
    return <LoadingPage />;
  }

  return (
    <Stack
      sx={{
        flex: 1,
        background: defaultColors.genfBlue,
      }}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                         CONFIRMATION DIALOG                    //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <StopConfirmModal
        open={stopConfirmModalOpen}
        setOpen={setStopConfirmModalOpen}
        startTime={startTime}
        onConfirm={handleConfirmStopSurvey}
        onReject={() => setStopConfirmModalOpen(false)}
      />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           NAVIGATION BAR                       //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <NavigationBar />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                            CHAT SECTION                        //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {uuid && didLoadInitialMessages && (
        <Stack
          sx={{
            flex: 1,
            justifyContent: 'center',
            alignItems: isMobile ? undefined : 'center',
          }}
        >
          {/* //////////////////////////////////////////////////////////////////// */}
          {/* ////                        CHAT INTERFACE                      //// */}
          {/* //////////////////////////////////////////////////////////////////// */}

          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%', height: '100%' }}
          >
            <Stack
              sx={{ width: '30%', paddingTop: '10%', fontWeight: 600 }}
              spacing={2}
              p={4}
            >
              <CopySmall
                sx={{
                  textAlign: 'left',
                  lineHeight: '2rem',
                  fontSize: '1.2rem',
                }}
              >
                1. Pour parler, appuyez et gardez la barre d’espace enfoncée.
              </CopySmall>
              <CopySmall
                sx={{
                  textAlign: 'left',
                  lineHeight: '2rem',
                  fontSize: '1.2rem',
                }}
              >
                2. Quand vous avez fini de parler, lâchez la barre d’espace.
              </CopySmall>
              <Stack
                sx={{
                  background: '#282c30',
                  p: 2,
                  pt: 1,
                  borderRadius: '10px',
                }}
              >
                <Header3
                  sx={{
                    fontSize: '1.2rem',
                  }}
                >
                  Légende de couleur:
                </Header3>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Box
                    sx={{
                      background: '#EEE',
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                    }}
                  />
                  <CopySmall sx={{ fontSize: '1rem' }}>
                    Le chatbot attend que vous lui parlez.
                  </CopySmall>
                </Stack>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Box
                    sx={{
                      background: '#91e3a1',
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                    }}
                  />
                  <CopySmall sx={{ fontSize: '1rem' }}>
                    Le chatbot vous écoute.
                  </CopySmall>
                </Stack>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Box
                    sx={{
                      background: '#e3d691',
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                    }}
                  />
                  <CopySmall sx={{ fontSize: '1rem' }}>
                    Le chatbot réfléchit à une réponse.
                  </CopySmall>
                </Stack>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Box
                    sx={{
                      background: '#91b1e3',
                      height: '16px',
                      width: '16px',
                      borderRadius: '50%',
                    }}
                  />
                  <CopySmall sx={{ fontSize: '1rem' }}>
                    Le chatbot vous parle.
                  </CopySmall>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                overflow: 'hidden',
                boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.1)',
                borderRadius: isMobile ? '0px' : '13px',
                maxHeight: isMobile ? '100%' : '800px',
                height: isMobile ? '100%' : '90%',
                width: isMobile ? '100%' : '80%',
                maxWidth: isMobile ? '100%' : '800px',
              }}
            >
              <ChatUI
                chatUuid={uuid}
                onStopButtonPress={handleStopButtonPress}
                preloaded_messages={messages}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default PXChatPage;
