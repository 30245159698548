import { Box, Stack } from '@mui/material';
import UserProfileMenu from './UserProfileMenu';

const NavigationBar = () => {
  return (
    <Stack
      sx={{
        position: 'relative',
        height: '50px',
        width: '100%',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          width: '50px',
        }}
      >
        <UserProfileMenu />
      </Box>
    </Stack>
  );
};

export default NavigationBar;
