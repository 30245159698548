import { Dispatch, FC, SetStateAction, useContext } from "react";

// MUI
import { Stack } from "@mui/system";
import { Box, Dialog, DialogContent } from "@mui/material";

import { CopySmall, Header3 } from "../../Typography";
import { UICtx } from "../../../providers/UIProvider";

interface LegendProps {
  onClose?: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const Legend: FC<LegendProps> = ({ onClose, open = false }) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Dialog
      onClose={onClose}
      open={open}
      // Paper props
      PaperProps={{
        sx: {
          background: "#282c30",
          borderRadius: "10px",
        },
      }}
    >
      <DialogContent>
        <Stack
          sx={{
            background: darkMode ? "#282c30" : undefined,
            p: 1,
            px: 2,
            borderRadius: "10px",
          }}
        >
          <Header3
            sx={{
              fontSize: "1.2rem",
            }}
          >
            Légende de couleur:
          </Header3>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                background: "#EEE",
                height: "16px",
                width: "16px",
                borderRadius: "50%",
              }}
            />
            <CopySmall sx={{ fontSize: "0.7rem" }}>
              Le chatbot attend que tu lui parles.
            </CopySmall>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                background: "#91e3a1",
                height: "16px",
                width: "16px",
                borderRadius: "50%",
              }}
            />
            <CopySmall sx={{ fontSize: "0.7rem" }}>
              Le chatbot t'écoute.
            </CopySmall>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                background: "#e3d691",
                height: "16px",
                width: "16px",
                borderRadius: "50%",
              }}
            />
            <CopySmall sx={{ fontSize: "0.7rem" }}>
              Le chatbot réfléchit à une réponse.
            </CopySmall>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                background: "#91b1e3",
                height: "16px",
                width: "16px",
                borderRadius: "50%",
              }}
            />
            <CopySmall sx={{ fontSize: "0.7rem" }}>
              Le chatbot te parle.
            </CopySmall>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Legend;
