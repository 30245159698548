import { getAuth, ParsedToken, User } from 'firebase/auth';
import React, { createContext, useContext } from 'react';
import { UICtx } from './UIProvider';
import { REACT_APP_DEBUG } from '../config';

// --------------------------------------------------------
// Context to hold Authenticated user.

interface IAuthStateProps {
  user: User | null;
  isAdmin: boolean;
  customClaims: ParsedToken | null;
  loading: boolean;
  permissions: string[];
  roles: string[];
  hasStopped: boolean;
}

export const AuthCtx = createContext({} as IAuthStateProps);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { addNotification } = useContext(UICtx);

  // The user as it is, on firebase
  const [user, setUser] = React.useState<User | null>(null);
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true);
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [roles, setRoles] = React.useState<string[]>([]);
  const [customClaims, setCustomClaims] = React.useState<ParsedToken | null>(
    null
  );
  const [isAdmin, setAdmin] = React.useState<boolean>(false);
  const [hasStopped, setHasStopped] = React.useState<boolean>(false);

  React.useEffect(() => {
    const auth = getAuth();
    // if (process.env.REACT_APP_DEBUG === 'true') {
    //   connectAuthEmulator(auth, '127.0.0.1:9099');
    // }
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && auth.currentUser) {
        try {
          const idTokenResult = await auth.currentUser.getIdTokenResult(true);
          if (REACT_APP_DEBUG) {
            console.log('idTokenResult', idTokenResult);
          }
          const claims = idTokenResult.claims;
          setCustomClaims(claims);
          if (claims['permissions']) {
            const userPermissions = claims['permissions'] as string[];
            if (userPermissions.length > 0) {
              setPermissions(userPermissions);
            }
          }
          if (claims['roles']) {
            const customClaimPermissions = claims['roles'] as
              | string[]
              | undefined;
            if (customClaimPermissions) {
              setRoles(customClaimPermissions);
            }
          }
          if (claims['isAdmin']) {
            const userIsAdmin = claims['isAdmin'] as string | undefined;
            if (userIsAdmin) {
              setAdmin(true);
            }
          }
          if (claims['hasStopped']) {
            const userHasStoppedSurvey = claims['isAdmin'] as
              | string
              | undefined;
            if (userHasStoppedSurvey) {
              setHasStopped(true);
            }
          }
          // const config = await headerConfig();
          // console.log(config);
        } catch (error) {
          console.log(error);
          addNotification({
            type: 'error',
            message: 'Failed to fetch user permissions',
          });
        }
      }
      setUser(firebaseUser);
      setLoadingAuth(false);
    });

    return unsubscribe;
  }, [addNotification]);

  return (
    <AuthCtx.Provider
      value={{
        user: user,
        isAdmin: isAdmin,
        loading: loadingAuth,
        customClaims: customClaims,
        permissions: permissions,
        roles: roles,
        hasStopped: hasStopped,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};
