import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const FixedColors = {
  almostBlack: '#080808',
  strokes: '#E3E3E3',
  inputField: '#F5F5F5',
  white: '#FFFFFF',
  blue: '#0000DB',
};

export const defaultColors = {
  almostBlack: '#080808',
  genfBlue: '#00b1ae',
};

let theme = createTheme({
  spacing: [0, 9, 18, 36, 44, 50, 60, 70, 80, 90, 100],
  palette: {
    primary: {
      main: '#0D1116',
      light: '#878686',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FAFAFA',
      light: '#C2BEBE',
      dark: '#e2e2e2',
      contrastText: '#565656',
    },
    info: {
      main: '#181F28',
      light: '#2f353d',
      dark: '#181818',
      contrastText: '#ffffff',
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 34,
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 10,
      letterSpacing: '0.1em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: '193px',
          height: '43px',
          textAlign: 'left',
          boxShadow: 'none',
          borderRadius: 4,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
