import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { createChat, deleteChat, getChats } from '../../API';
import { UICtx } from '../../providers/UIProvider';
import { defaultColors } from '../../theme';
import NavigationBar from '../../components/NavigationBar';
import { DataCtx } from '../../providers/DataProvider';

const StyledSectionCard = styled(Stack)(() => ({
  boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '13px',
  background: 'white',
  height: '500px',
  width: '500px',
}));

const PXPage = () => {
  // Navigation Hook
  const navigate = useNavigate();

  // Context
  const { isMobile } = useContext(UICtx);
  const { isLoading, chats, fetchData } = useContext(DataCtx);

  // State
  const [language, setLanguage] = useState('fr-FR');

  // Local Loading
  const [localLoading, setLocalLoading] = useState<boolean>(true);

  const handleCreateNewChat = async () => {
    setLocalLoading(true);
    await createChat({ language: language });
    setLocalLoading(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };

  const handleDeleteChat = async (chat_id: string) => {
    await deleteChat(chat_id);
    fetchData();
  };
  // Use Effect to automatically create a chat if there are no chats
  useEffect(() => {
    const initializeNewChat = async () => {
      setLocalLoading(true);
      try {
        const response = await getChats();
        if (response.result.length > 0) {
          navigate(`/${response.result[0].id}`);
        } else {
          const new_chat = await createChat({ language: language });
          navigate(`/${new_chat.id}`);
        }
      } catch (error: unknown) {
        console.error(error);
        if (error instanceof Error && 'response' in error) {
          const axiosError = error as { response?: { status: number } };
          if (axiosError.response && axiosError.response.status === 403) {
            navigate('/');
          }
        }
      } finally {
        setLocalLoading(false);
      }
    };
    if (!isLoading && chats.length === 0) {
      initializeNewChat();
    } else if (!isLoading && chats.length > 0) {
      navigate(`/${chats[0].id}`);
    }
  }, [isLoading, language, chats, navigate]);

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: defaultColors.genfBlue,
      }}
    >
      <NavigationBar />
      <StyledSectionCard
        sx={{
          overflow: 'hidden',
          boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.1)',
          borderRadius: isMobile ? '0px' : '13px',
          maxHeight: isMobile ? '100%' : '600px',
          height: isMobile ? '100%' : '80%',
          width: isMobile ? '100%' : '80%',
          maxWidth: isMobile ? '100%' : '800px',
          background: '#1e1e1e',
          justifyContent: 'center',
          alignItems: isMobile ? undefined : 'center',
          p: 4,
        }}
        spacing={1}
      >
        {!localLoading && !isLoading && (
          <Stack
            direction={'row'}
            sx={{
              background: ' #3e3e3e',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                color: 'white',
                // background: ' #3e3e3e',
                border: 'none',
                width: '100%',
              }}
              onClick={handleCreateNewChat}
            >
              Create a new chat
            </Button>
            <FormControl sx={{ width: '150px' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label="Language"
                onChange={handleChange}
                sx={{
                  color: 'white',
                }}
              >
                <MenuItem value={'en-US'}>English</MenuItem>
                <MenuItem value={'fr-FR'}>French</MenuItem>
                <MenuItem value={'de-DE'}>German</MenuItem>
                <MenuItem value={'es-ES'}>Spanish</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        )}
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'white',
          }}
        >
          {/* do we show something here? */}
        </Typography>
        {chats.map((chat) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Button
              key={chat.id}
              sx={{
                color: 'white',
                background: ' #3e3e3e',
                border: 'none',
                flexGrow: 1,
                textTransform: 'none',
              }}
              onClick={() => navigate(`/${chat.id}`)}
            >
              START
            </Button>
            <Button
              sx={{
                color: 'white',
                background: '#3e3e3e',
                border: 'none',
                minWidth: 'auto',
              }}
              onClick={() => handleDeleteChat(chat.id)}
            >
              Delete
            </Button>
          </Stack>
        ))}
      </StyledSectionCard>
    </Stack>
  );
};

export default PXPage;
