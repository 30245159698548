import { useContext, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { UICtx } from "../../providers/UIProvider";
// import NavigationBar from "../../components/NavigationBar";
import { getCompletionCode } from "../../API";

const CompletionCodePage = () => {
  const { isMobile } = useContext(UICtx);

  const [completionCode, setCompletionCode] = useState<string>("");

  const fetchCompletionCode = async () => {
    const resp = await getCompletionCode();
    setCompletionCode(`${resp.completionCode}`);
  };

  useEffect(() => {
    fetchCompletionCode();
  }, []);

  return (
    <Stack
      sx={{
        flex: 1,
        // alignItems: 'center',
        background: isMobile
          ? "#1e1e1e"
          : "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      }}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           NAVIGATION BAR                       //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {/* <NavigationBar /> */}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           CONTENT SECTION                      //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Stack
        sx={{
          flex: 1,
          justifyContent: "center",
          alignItems: isMobile ? undefined : "center",
        }}
      >
        <Stack
          sx={{
            overflow: "hidden",
            boxShadow: "3px 3px 15px rgba(0, 0, 0, 0.1)",
            borderRadius: isMobile ? "0px" : "13px",
            maxHeight: isMobile ? "100%" : "600px",
            height: isMobile ? "100%" : "70%",
            width: isMobile ? "100%" : "80%",
            maxWidth: isMobile ? "100%" : "800px",
            background: "#1e1e1e",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <Stack sx={{ maxWidth: isMobile ? "90%" : "70%" }}>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "white",
              }}
            >
              Nous vous remercions d'avoir discuté avec notre chatbot.
              <br />
              Veuillez insérer le code suivant dans Qualtrics lorsque vous
              retournerez à votre questionnaire.
            </Typography>
          </Stack>

          <Typography
            sx={{
              fontSize: "4rem",
              fontWeight: "bold",
              textAlign: "center",
              color: "white",
            }}
          >
            {completionCode}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompletionCodePage;
