import { Stack, Typography } from "@mui/material";
import { FC } from "react";

interface LoadingViewProps {
  withMessage?: string;
}

const LoadingView: FC<LoadingViewProps> = ({ withMessage }) => {
  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>{withMessage || "Loading..."}</Typography>
    </Stack>
  );
};
export default LoadingView;
