import { Button, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UICtx } from "../../providers/UIProvider";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const { darkMode } = useContext(UICtx);

  const { isMobile } = useContext(UICtx);

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: darkMode ? "black" : "white",
        p: 4,
      }}
      spacing={1}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          textTransform: "uppercase",
          fontSize: isMobile ? "8rem" : "15rem",
          lineHeight: isMobile ? "8rem" : "15rem",
          letterSpacing: isMobile ? "-0.2rem" : "-0.5rem",
          color: darkMode ? "white" : "black",
        }}
      >
        4<span style={{ color: "#d9f400" }}>0</span>3
      </Typography>
      <Typography
        sx={{
          fontFamily: "Roboto",
          textTransform: "uppercase",
          fontSize: isMobile ? "2rem" : "5rem",
          lineHeight: isMobile ? "2rem" : "5rem",
          color: darkMode ? "white" : "black",
          textAlign: "center",
        }}
      >
        Sorry
      </Typography>
      <Typography
        sx={{
          fontFamily: "Roboto",
          textTransform: "uppercase",
          fontSize: isMobile ? "2rem" : "5rem",
          lineHeight: isMobile ? "2rem" : "5rem",
          color: "#d9f400",
          textAlign: "center",
        }}
      >
        Unauthorized 🫠
      </Typography>
      <Button
        onClick={() => navigate("/")}
        sx={{
          marginTop: isMobile ? "1.5rem !important" : "2rem !important",
          backgroundColor: "#d9f400",
          borderWidth: "2px",
          fontWeight: "bold",
          borderRadius: "40px",
          "&:hover": {
            backgroundColor: "#d9f400",
          },
        }}
      >
        GO BACK
      </Button>
    </Stack>
  );
};
export default UnauthorizedPage;
