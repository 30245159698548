import {
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
} from "react";

// MUI
import { Stack } from "@mui/system";

// Assets
import { useMediaQuery } from "usehooks-ts";
import { BotState } from "../../../ifaces";

interface BotFaceProps {
  state?: BotState;
  darkMode?: boolean;
  containerWidth?: number;
  containerHeight?: number;
}

const BotFace: FC<BotFaceProps> = ({
  state = BotState.IDLE,
  darkMode = false,
  containerHeight,
  containerWidth,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [size, setSize] = useState<number>(0);

  const SVGRef = useRef<SVGSVGElement>(null);

  const [borderColor, setBorderColor] = useState<string>("#EEE");

  function calculateCurrentFrame(currentTimeMs: number, totalTimeMs: number) {
    // Determine how far into the current loop we are
    const timeInCurrentLoop = currentTimeMs % totalTimeMs;

    // Calculate the current frame based on the time within the current loop
    const currentFrame = Math.floor(timeInCurrentLoop);

    return currentFrame;
  }

  // Draw on canvas
  useLayoutEffect(() => {
    if (!SVGRef.current) {
      return;
    }

    // Find out number of frames in SVG. Frames have an id of the form "frame<number>"
    const frames = SVGRef.current.querySelectorAll('[id^="frame"]');
    const frameObjs: {
      duration: number;
      startFrame: number;
      endFrame: number;
      frame: Element;
    }[] = [];

    // Retrieve the duration from the frame-duration attribute
    let totalDuration = 0;
    frames.forEach((frame) => {
      const duration = parseInt(
        frame.getAttribute("frame-duration") || "0",
        10
      );
      totalDuration += duration;
      frameObjs.push({
        duration,
        frame,
        startFrame: totalDuration - duration,
        endFrame: totalDuration,
      });
    });
    // console.log('totalDuration', totalDuration);

    let animationFrameId = 0;

    // Our render loop
    const render = () => {
      // Calculate the current frame using the current time, the animationFrameId and the total duration
      const currentFrame = calculateCurrentFrame(
        performance.now(),
        totalDuration
      );

      frameObjs.forEach(({ frame, startFrame, endFrame }) => {
        const inFrame = currentFrame < endFrame && currentFrame >= startFrame;

        // Show/hide the frame
        frame.setAttribute("display", inFrame ? "inline" : "none");
      });

      // Request the next frame
      animationFrameId = requestAnimationFrame(render);
    };

    render();

    // Cleanup on unmount
    return () => cancelAnimationFrame(animationFrameId);
  }, [SVGRef]);

  const getBorderColor = (botState: BotState): string => {
    switch (botState) {
      case BotState.IDLE:
        return "#EEE";
      case BotState.LISTENING:
        // Light Green
        return "#91e3a1";
      case BotState.THINKING:
        // Light mustard
        return "#e3d691";
      case BotState.SPEAKING:
        return "#91b1e3";
      default:
        return "#EEE";
    }
  };

  useEffect(() => {
    setBorderColor(getBorderColor(state));
  }, [state]);

  useEffect(() => {
    // Get the smaller value between the container height and width
    const newSize = Math.min(containerHeight || 0, containerWidth || 0);
    setSize(newSize * 0.8);
  }, [containerHeight, containerWidth]);

  const BlinkSVGWithRef = forwardRef<
    SVGSVGElement,
    { width: number; height: number }
  >(() => (
    <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="frame1" frame-duration="6000">
        <rect
          x="149.5"
          y="131.5"
          width="54"
          height="134"
          rx="27"
          stroke="black"
          stroke-width="11"
        />
        <rect
          x="300.5"
          y="131.5"
          width="54"
          height="134"
          rx="27"
          stroke="black"
          stroke-width="11"
        />
      </g>
    </svg>
  ));

  return (
    <Stack
      sx={{
        background: borderColor,
        height: `${size}px`,
        width: `${size}px`,
        p: isMobile ? 2 : 3,
        borderRadius: "50%",
      }}
    >
      <Stack
        sx={{
          background: darkMode ? "white" : "#282c30",
          borderRadius: "50%",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BlinkSVGWithRef ref={SVGRef} width={size * 0.8} height={size * 0.8} />
      </Stack>
    </Stack>
  );
};

export default BotFace;
