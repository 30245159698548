import React, { FC, useEffect, useState } from 'react';

// MUI
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

// Contexts
import { AuthCtx } from '../../providers/AuthProvider';

// CUSTOM
import GooglSignInDark from '../../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png';
import GooglSignInLight from '../../assets/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png';
import { UICtx } from '../../providers/UIProvider';
import { redeemInviteCode } from '../../API';
import CodeInputField from './CodeInputField';

// Uni Genf logo
import Logo from '../../assets/unige-logo.svg';
import { defaultColors } from '../../theme';
import { Copy, CopySmall } from '../../components/Typography';

interface WindowsLocationType {
  from: Location | undefined;
}

const SignInWithGoogleButton = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  backgroundImage: `url(${GooglSignInDark})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: 192,
  height: 48,
});

interface SignInPageProps {
  allowSignInWithCode?: boolean;
}

const SignInPage: FC<SignInPageProps> = ({ allowSignInWithCode }) => {
  // HOOKS
  const location = useLocation();
  const navigate = useNavigate();

  // CONTEXTS
  const { user, isAdmin, hasStopped } = React.useContext(AuthCtx);
  const { darkMode, responsiveSize: size, isMobile } = React.useContext(UICtx);

  // STATE
  const [signInCode, setSignInCode] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [signInCodeError, setSignInCodeError] = useState<string>('');

  const state = location.state as WindowsLocationType | undefined;
  const from = state?.from?.pathname || '/';

  const SIGN_IN_CODE_LENGTH = 4;

  useEffect(() => {
    if (user) {
      if (hasStopped && !isAdmin) {
        navigate('/completion', { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    }
  }, [from, navigate, user, hasStopped, isAdmin]);

  const handleSignInWithGoogle = async () => {
    // Sign in using firebase google auth provider
    setLoading(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    const handleSignInWithInviteCode = async () => {
      if (!signInCode) {
        return;
      }
      try {
        setLoading(true);
        const customTokenResponse = await redeemInviteCode(
          parseInt(signInCode)
        );
        const auth = getAuth();
        await signInWithCustomToken(auth, customTokenResponse.token);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setSignInCodeError('Invalid code');
      }
    };

    if (signInCode.length === SIGN_IN_CODE_LENGTH) {
      handleSignInWithInviteCode();
    }
  }, [signInCode]);

  const handleCodeChange = (code: string) => {
    if (code.length > 0 && code.length < SIGN_IN_CODE_LENGTH) {
      setSignInCodeError('');
    }
  };

  return (
    <Stack
      sx={{
        flex: 1,
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: defaultColors.genfBlue,
      }}
    >
      {isLoading ? (
        <CircularProgress sx={{ color: 'white' }} />
      ) : allowSignInWithCode ? (
        <Stack
          sx={{
            alignItems: 'center',
          }}
          spacing={5}
        >
          <Box
            sx={{
              backgroundImage: `url(${Logo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: 292,
              height: 100,
            }}
          />
          <Stack spacing={2} sx={{ px: isMobile ? 4 : 2, maxWidth: '800px' }}>
            <CopySmall
              sx={{
                textAlign: 'center',
                color: 'white',
              }}
            >
              Responsable de Recherche : Neele H. Heiser, neele.heiser@unige.ch.
            </CopySmall>
            <CopySmall
              sx={{
                textAlign: 'center',
                color: 'white',
              }}
            >
              Code participant :
            </CopySmall>
          </Stack>
          <CodeInputField
            size={size}
            length={SIGN_IN_CODE_LENGTH}
            onChange={handleCodeChange}
            onSubmit={(code: string) => {
              setSignInCode(code);
            }}
          />
          {signInCodeError && (
            <Typography
              sx={{
                color: 'red',
                fontSize: '1rem',
                fontWeight: 'bold',
              }}
            >
              {signInCodeError}
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack
          spacing={2}
          minWidth={'400px'}
          sx={{
            background: 'white',
            borderRadius: '15px',
            padding: 10,
            alignItems: 'center',
          }}
        >
          <SignInWithGoogleButton
            onClick={() => handleSignInWithGoogle()}
            sx={{
              backgroundImage: `url(${
                darkMode ? GooglSignInDark : GooglSignInLight
              })`,
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default SignInPage;
