import MainLayout from '../MainLayout';
import LoadingView from './LoadingView';

const LoadingPage = () => {
  return (
    <MainLayout>
      <LoadingView />
    </MainLayout>
  );
};
export default LoadingPage;
