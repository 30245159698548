// Firebase
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Auth Context
import { AuthCtx } from '../providers/AuthProvider';
// Custom Components
import LoadingPage from './LoadingPage/LoadingPage';
import UnauthorizedPage from './403Page/UnauthorizedPage';

export interface SecureLayoutProps {
  title?: string;
  adminRequired?: boolean;
  needVerification?: boolean;
  requiresToken?: boolean;
  permissionRequired?: boolean;
  permissionName?: string;
}

const SecureLayout = ({
  children,
  adminRequired,
  needVerification = false,
  requiresToken = false,
  permissionRequired,
  permissionName,
}: { children: JSX.Element } & SecureLayoutProps) => {
  const location = useLocation();

  const {
    user,
    isAdmin,
    loading: loadingUser,
    permissions,
  } = React.useContext(AuthCtx);

  if (loadingUser) {
    return <LoadingPage />;
  } else if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    //	TODO: Uncomment the following line to apply authentication.
    if (requiresToken) {
      return <Navigate to="/auth" state={{ from: location }} replace />;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  if (adminRequired && !isAdmin) {
    return <UnauthorizedPage />;
  }

  if (
    permissionRequired &&
    permissionName &&
    permissions.indexOf(permissionName) === -1
  ) {
    return <UnauthorizedPage />;
  }

  if (!user.emailVerified && needVerification) {
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default SecureLayout;
