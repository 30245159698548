import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';

// MUI
import { Stack } from '@mui/material';

// CUSTOM
import DigitInputField from './DigitInputField';

const isNumber = (str: string) => /^[0-9]*$/.test(str);

interface CodeInputFieldProps {
  size?: 'sm' | 'md' | 'lg';
  length?: number;
  onChange: (code: string) => void;
  onSubmit: (code: string) => void;
}

const CodeInputField: FC<CodeInputFieldProps> = ({ size = 'md', length = 8, onChange, onSubmit }) => {
  // CONTEXTS
  // const { darkMode } = React.useContext(UICtx);

  // STATE
  const [signInCode, setSignInCode] = useState<string>('');
  const [localInputStates, setLocalInputStates] = useState<string[]>(Array(length).fill(''));
  const inputRefs = useRef<React.RefObject<HTMLInputElement>[]>(
    Array(length).fill(null).map(() => React.createRef<HTMLInputElement>())
  );

  const concatenateCode = useCallback(() => {
    return localInputStates.join('');
  }, [localInputStates]);

  useEffect(() => {
    const currentCode = concatenateCode();
    onChange(currentCode);
    if (currentCode.length === length) {
      setSignInCode(currentCode);
    } else {
      setSignInCode('');
    }
  }, [onChange, concatenateCode, length]);

  const handleKeyPress = (fieldIndex: number, event: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
    const newVal = event.key;
    const newValIsNumber = isNumber(newVal);
    
    if (newValIsNumber) {
      const newLocalInputStates = [...localInputStates];
      newLocalInputStates[fieldIndex] = newVal;
      setLocalInputStates(newLocalInputStates);

      if (fieldIndex < length - 1 && newVal.length === 1) {
        inputRefs.current[fieldIndex + 1]?.current?.focus();
      }
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      const newLocalInputStates = [...localInputStates];
      newLocalInputStates[fieldIndex] = '';
      setLocalInputStates(newLocalInputStates);

      if (fieldIndex > 0) {
        inputRefs.current[fieldIndex - 1]?.current?.focus();
      }
    }
    event.preventDefault();
  };

  const handleTextChange = (fieldIndex: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newVal = event.target.value;
    if (!isNumber(newVal)) return;

    const newLocalInputStates = [...localInputStates];
    newLocalInputStates[fieldIndex] = newVal;
    setLocalInputStates(newLocalInputStates);

    if (fieldIndex < length - 1 && newVal.length === 1) {
      inputRefs.current[fieldIndex + 1]?.current?.focus();
    } else if (fieldIndex > 0 && newVal.length === 0) {
      inputRefs.current[fieldIndex - 1]?.current?.focus();
    }
  };

  useEffect(() => {
    onSubmit(signInCode);
  }, [signInCode, onSubmit]);

  return (
    <Stack direction={'row'} spacing={size === 'lg' ? 2 : 1}>
      {Array.from({ length }, (_, index) => (
        <DigitInputField
          key={index}
          size={size}
          autoFocus={index === 0}
          codeInputNumber={index + 1}
          localInputState={localInputStates[index]}
          localInputStateRef={inputRefs.current[index]}
          handleTextChange={(_, event) => handleTextChange(index, event)}
          handleKeyPress={(_, event) => handleKeyPress(index, event)}
        />
      ))}
    </Stack>
  );
};

export default CodeInputField;
