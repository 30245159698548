import { FC, useContext, useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../Firebase';
import defaultAvatar from '../assets/avatar-default.png';
import { AuthCtx } from '../providers/AuthProvider';

interface UserProfileImageProps {
  size?: number;
  imageStoragePath?: string;
}

const UserProfileImage: FC<UserProfileImageProps> = ({ size = 40, imageStoragePath }) => {
  const { user } = useContext(AuthCtx);

  const [imageURL, setImageURL] = useState<string>();

  const getImageURL = async (path: string) => {
    try {
      const downloadURL = await getDownloadURL(ref(storage, path));
      setImageURL(downloadURL);
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  useEffect(() => {
    if (imageStoragePath) {
      getImageURL(imageStoragePath);
    }
  }, [imageStoragePath]);

  useEffect(() => {
    if (user?.photoURL) {
      setImageURL(user.photoURL);
    }
  }, [user]);
  return (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        height={`${size}`}
        width={`${size}`}
        style={{ borderRadius: `${size}px` }}
        src={imageURL || defaultAvatar}
        alt={'Profile'}
      />
    </div>
  );
};

export default UserProfileImage;
