import { FC, useContext, useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { CopyBold, CopySmall } from "../Typography";
import { UICtx } from "../../providers/UIProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  p: 5,
};

interface ConfirmationModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm?: () => void;
  onReject?: () => void;
  startTime?: Date;
}
const StopConfirmModal: FC<ConfirmationModalProps> = ({
  open,
  setOpen,
  onConfirm,
  onReject,
  startTime,
}) => {
  const { darkMode } = useContext(UICtx);
  const [timeElapsed, setTimeElapsed] = useState<number>(0);

  const handleClose = () => setOpen(false);

  const formatTimeElapsedInApproxMinutes = (time: number) => {
    const minutes = Math.floor(time / 1000 / 60);
    return minutes;
  };

  useEffect(() => {
    if (startTime) {
      const interval = setInterval(() => {
        const now = new Date();
        const elapsed = now.getTime() - startTime.getTime();
        setTimeElapsed(elapsed);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startTime]);

  return (
    <Modal
      aria-labelledby="stop-confirm-modal"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          component={Stack}
          sx={{ ...style, background: darkMode ? "#1e1e1e" : "#fff" }}
          spacing={2}
        >
          <CopyBold>Êtes-vous sûr-e de vouloir quitter la discussion?</CopyBold>
          <CopySmall sx={{ mt: 2, fontWeight: 500 }}>
            Vous êtes en conversation avec le chatbot depuis{" "}
            <strong>{formatTimeElapsedInApproxMinutes(timeElapsed)}</strong>{" "}
            minutes. Vous avez la possibilité de mettre fin à cette session
            maintenant ou de poursuivre l'échange pendant encore un peu de
            temps.
            {/* <strong>{formatTimeElapsedInApproxMinutes(timeElapsed)}</strong> minutes since you started chatting with the
            bot. You can stop now or continue for a few minutes more. */}
          </CopySmall>
          <Stack direction={"row"} spacing={2} justifyContent={"center"}>
            <Button
              onClick={onReject}
              sx={{
                background: "#EFEFEF",
                "&:hover": {
                  background: "#EFEFEF",
                },
              }}
            >
              Continue
            </Button>
            <Button
              onClick={onConfirm}
              sx={{
                background: "#91e3a1",
                "&:hover": {
                  background: "#91e3a1",
                },
              }}
            >
              FIN
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default StopConfirmModal;
